<template>
  <div>
    <div ref="NoticeRef" :class="isChat ? '' : 'mb-2'">
      <el-alert show-icon type="warning" class="border align-items-start" :closable="!isChat">
        <template v-slot:title>
          <div class="text-2xl text-yellow-900">Thông báo</div>
        </template>
        <div>
          <div class="mb-2 text-sm text-yellow-900">
            <template v-if="isOwner">
              <p class="mb-0">Kể từ 15/01/2024, phiên bản Chat 1.0 sẽ dừng phục vụ. Bạn vẫn có thể tiếp tục truy cập tin
                nhắn cũ.</p>
              <p class="mb-0">Phiên bản Chat 2.0 nâng cấp tính năng Mã hoá đầu cuối (End 2 End Encryption) đã sẵn sàng.
                Bạn có thể Nâng cấp ngay!</p>
            </template>
            <template v-else>
              <p class="mb-0">Kể từ 15/01/2024, phiên bản Chat 1.0 sẽ dừng phục vụ. Bạn vẫn có thể tiếp tục truy cập tin
                nhắn cũ.</p>
              <p class="mb-0">Phiên bản Chat 2.0 nâng cấp tính năng Mã hoá đầu cuối (End 2 End Encryption) đã sẵn sàng.
                Bạn có thể đề nghị Admin (Seed) của Circle Nâng cấp ngay.</p>
            </template>
          </div>
          <div class="flex gap-2">
            <div v-if="isOwner && !isMigrated">
              <el-button type="primary" @click="handleMigrateConversation()">Chuyển sang phiên bản mới</el-button>
            </div>
            <div v-if="isMigrated">
              <el-button type="primary" plain @click="goToChat($event, circleData)">Truy cập phiên bản mới</el-button>
            </div>
            <div v-if="isChat">
              <el-button type="primary" plain @click="handleUseChatV1">Tiếp tục sử dụng Chat 1.0</el-button>
            </div>
          </div>
        </div>
      </el-alert>
    </div>
    <transition name="el-fade-in-linear">
      <div v-show="isMigrateProcessing" class="fixed inset-0 top-0 left-0 bg-white flex transition-all duration-300"
        style="z-index: 2000;">
        <div class="m-auto text-center text-2xl">
          <div class="text-5xl mb-4">
            <i v-if="isMigrateSuccessfully && (!isMigrateFailed || isMigrateFailed === null)"
              class="el-icon-success text-green-500" />
            <i v-else-if="isMigrateFailed" class="el-icon-error text-red-500" />
            <i v-else class="el-icon-loading text-blue-800" />
          </div>
          <div class="mb-4">
            <ul class="p-0 m-0">
              <li class="flex align-items-center justify-content-center">
                <template v-if="isCreatedChannel !== null && (isCreatingChannel !== null && !isCreatingChannel)">
                  <i v-if="isCreatedChannel" class="el-icon-success text-green-500" />
                  <i v-else class="el-icon-error text-red-500" />
                </template>
                <template v-if="isCreatingChannel && isCreatedChannel === null">
                  <i class="el-icon-loading" />
                </template>
                <template v-if="isCreatingChannel === null && isCreatedChannel === null">
                  <i class="el-icon-warning-outline" />
                </template>
                <span class="ml-2">Khởi tạo kênh hội thoại mới</span>
              </li>
              <li class="flex align-items-center justify-content-center">
                <template v-if="isEncryptedChannel !== null && (isEncryptingChannel !== null && !isEncryptingChannel)">
                  <i v-if="isEncryptedChannel" class="el-icon-success text-green-500" />
                  <i v-else class="el-icon-error text-red-500" />
                </template>
                <template v-if="isEncryptingChannel && isEncryptedChannel === null">
                  <i class="el-icon-loading" />
                </template>
                <template v-if="isEncryptingChannel === null && isEncryptedChannel === null">
                  <i class="el-icon-warning-outline" />
                </template>
                <span class="ml-2">Mã hóa kênh hội thoại</span>
              </li>
            </ul>
          </div>
          <template v-if="isMigrateSuccessfully && (!isMigrateFailed || isMigrateFailed === null)">
            <div class="mb-4">Quá trình chuyển đổi đã thành công.</div>
            <div class="flex align-items-center justify-content-center">
              <el-button type="primary" @click="goToChat($event, circleData)">Truy cập phiên bản mới</el-button>
              <el-button plain @click="closeMigrate()">Đóng</el-button>
            </div>
          </template>
          <template v-else-if="isMigrateFailed">
            <div class="mb-4" v-if="userIdsNotRegister.length">
              <div class="mb-3">Không thể chuyển đổi do có thành viên đã lâu chưa Online.</div>
              <div class="max-w-3xl mx-auto">
                <div class="-mx-3 flex flex-wrap justify-content-center">
                  <div class="px-3" v-for="(user) in userIdsNotRegister" :key="user.id">
                    <div class="mb-2 flex justify-content-center">
                      <ImageBox v-if="user && user.avatar"
                        class="avatar avatar-sm avatar-rounded object-cover object-top w-16 h-16 border" avatar
                        :source="user && appUtils.getImageURL(user.avatar)" :alt="user && user.name" />
                      <span v-else class="avatar avatar-sm avatar-rounded bg-pri text-white text-3xl" style="
                          width: 64px;
                          height: 64px;
                          border-radius: 9999px;
                          background-size: 64px;
                          background-position: center;">{{ user && user.name.charAt(0) }}</span>
                    </div>
                    <div class="text-center text-lg">
                      <span>{{ user && user.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-2">Quá trình chuyển đổi đã thất bại.</div>
            <div class="mb-4">Vui lòng thực hiện lại hoặc liên hệ với ADMIN.</div>
            <div class="flex align-items-center justify-content-center">
              <el-button v-if="userIdsNotRegister.length" type="primary" @click="confirmNextMigrate()">Xác nhận tiếp tục
                chuyển đổi</el-button>

              <el-button :type="!userIdsNotRegister.length ? 'primary' : ''" plain
                @click="closeMigrate()">Đóng</el-button>
            </div>
          </template>
          <template v-else>
            <div>Đang thực hiện quá trình chuyển đổi</div>
            <div>Vui lòng không rời màn hình và chờ trong giây lát.</div>
            <div>Cảm ơn!</div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import e3kit from '@/libs/e3kit'
import appUtils from '@/utils/appUtils'
import { mapActions } from 'vuex'
import { mixinGoToChat } from '../../../utils/mixinGoToChat'

export default {
  name: 'MigratedChatV2',
  props: {
    circleData: Object,
    isOwner: Boolean,
    isChat: Boolean
  },
  mixins: [mixinGoToChat],
  data () {
    return {
      isDevMode: process.env.NODE_ENV === 'development',
      appUtils,
      channelId: null,
      channel: null,
      userIdsNotRegister: [],
      isChecking: true,
      isMigrateProcessing: false,
      isMigrateSuccessfully: null,
      isMigrateFailed: null,
      // Migrate states
      isCreatingChannel: null,
      isCreatedChannel: null,
      isEncryptingChannel: null,
      isEncryptedChannel: null,
      noticeHeight: 0
    }
  },
  computed: {
    isMigrated () {
      return (
        this.$conversationsClient &&
        this.circleData?.twilio_conversation?.twilio_conv_id &&
        this.circleData?.migrate_channel_gs?.channel_id &&
        !this.circleData?.member_not_joined_chat?.includes(
          String(this.$user?.id)
        ) &&
        (this.isOwner || this.circleData.request_chat === 2)
      )
    },

    orgInfo () {
      return this.$globalClinic?.organization || {}
    }
  },
  methods: {
    ...mapActions('app', ['refreshE3kitToken']),
    async handleMigrateConversation (isNextMigrate = false) {
      const twChannelId = this.circleData?.twilio_conversation?.twilio_conv_id

      if (!this.$e3kit || !e3kit || !twChannelId) {
        return
      }

      this.isMigrateProcessing = true

      const data = {
        twillio_channel_id: twChannelId,
        next_migrate: isNextMigrate
      }

      try {
        this.isCreatingChannel = true

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createNewChannel(data)

        this.isCreatingChannel = false

        if (res.data?.code === 200032) {
          this.$message({
            type: 'error',
            message:
              'Không thể chuyển đổi vì Circle chỉ có 1 thành viên là chính bạn.'
          })
          this.isCreatedChannel = false

          return
        }

        const ownerId = String(this.$user.id)
        const channelCId = res.data?.migrate_channel_get_streams?.cid
        const memberIds = (!isNextMigrate
          ? res.data?.user_members
          : res.data?.user_migrated
        )
          ?.map(m => m?.id?.toString())
          ?.sort()

        if (memberIds.length === 1) {
          this.$message({
            type: 'error',
            message:
              'Không thể chuyển đổi vì Circle chỉ có 1 thành viên là chính bạn.'
          })
          this.isCreatedChannel = false

          return
        }

        const participantIds = Object.values(memberIds || {}).filter(
          m => m !== ownerId
        )

        this.userIdsNotRegister = !isNextMigrate
          ? [...(res.data?.user_not_migrate || [])]
          : []

        if (this.userIdsNotRegister.length && !isNextMigrate) {
          this.$message({
            type: 'error',
            message: 'Không thể chuyển đổi do có thành viên đã lâu chưa Online.'
          })
          this.isCreatedChannel = false
          this.isMigrateFailed = true

          return
        }

        if (!channelCId) {
          this.$message({
            type: 'error',
            message: 'Không tìm thấy Channel ID.'
          })
          this.isCreatedChannel = false
          return
        }

        this.isCreatedChannel = true
        this.isEncryptingChannel = true

        const groupId = `group_${channelCId}`

        await e3kit.deleteGroup(groupId)
        const group = await e3kit.createGroup(groupId, participantIds, ownerId)

        this.isEncryptingChannel = false

        console.log(group)

        if (group) {
          this.isEncryptedChannel = true
          this.isMigrateSuccessfully = true
          this.$message({
            type: 'success',
            message: 'Quá trình chuyển đổi đã thành công!'
          })
        } else {
          this.isEncryptedChannel = false
          this.$message({
            type: 'warning',
            message:
              'Quá trình chuyển đổi đã xảy ra vấn đề. Vui lòng liên hệ với ADMIN để xử lý.'
          })
          this.isMigrateSuccessfully = false
        }

        this.$emit('onRefresh')
      } catch (error) {
        this.isEncryptingChannel = false
        this.isEncryptedChannel = false
        this.isMigrateSuccessfully = false

        console.log(error)
        if (error.data?.code === 200032) {
          this.isCreatingChannel = false
          this.isCreatedChannel = false
          this.$message({
            type: 'error',
            message:
              'Không thể chuyển đổi vì Circle chỉ có 1 thành viên là chính bạn.'
          })
        }

        if (error.data?.code === 500001) {
          this.isCreatingChannel = false
          this.isCreatedChannel = false
          this.$message({
            type: 'error',
            message: 'Không thể chuyển đổi vì bạn không phải Admin của Circle.'
          })
        }

        if (!error.data?.code && error.data?.message) {
          this.isCreatingChannel = false
          this.isCreatedChannel = false
        }

        if (error.message === 'JWT is expired') {
          // Refresh Encryption Token
          await this.handleDeleteChannel()
          await this.refreshE3kitToken()

          this.isCreatingChannel = null
          this.isCreatedChannel = null
          this.isEncryptingChannel = null
          this.isEncryptedChannel = null
          this.isMigrateFailed = null
          this.userIdsNotRegister = []

          await this.handleMigrateConversation()
        }
      } finally {
        this.isMigrateFailed =
          this.isMigrateSuccessfully === null ||
          (this.isMigrateSuccessfully !== null && !this.isMigrateSuccessfully)

        if (this.isMigrateFailed) {
          await this.handleDeleteChannel()
        }
      }
    },
    // goToConversation () {
    //   const domain =
    //     window.location.hostname === 'localhost'
    //       ? 'http://localhost:3000'
    //       : process.env.VUE_APP_HODO_CONVERSATION
    //   const routeRaw = `${domain}/redirect.html?token=${appUtils.getLocalToken()}&lang=${
    //     this.$lang
    //   }`

    //   console.log(routeRaw)
    //   // this.closeMigrate()

    //   if (appUtils.checkIsMobile()) {
    //     let linkElement = document.createElement('a')
    //     const linkId = `conversation_id_${appUtils.randomStr(6, true)}`
    //     linkElement.id = linkId
    //     linkElement.href = routeRaw
    //     window.document.body.appendChild(linkElement)

    //     const linkEl = document.getElementById(linkId)
    //     linkEl.setAttribute('rel', 'noopener noreferrer')
    //     !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
    //     linkEl.click()
    //     linkEl.remove()
    //     return
    //   }

    //   window.open(routeRaw, '_blank')
    // },

    async handleDeleteChannel () {
      const twChannelId = this.circleData?.twilio_conversation?.twilio_conv_id

      if (!twChannelId) return

      try {
        const data = {
          twilio_conv_id: twChannelId
        }
        await this.$rf.getRequest('DoctorRequest').deleteChannel(data)
      } catch (error) {
        console.log(error)
      } finally {
        // this.isCreatingChannel = false
        // this.isCreatedChannel = false
      }
    },
    closeMigrate () {
      this.isCreatingChannel = null
      this.isCreatedChannel = null
      this.isEncryptingChannel = null
      this.isEncryptedChannel = null
      this.isMigrateProcessing = false
      this.isMigrateFailed = null
      this.userIdsNotRegister = []
    },
    async confirmNextMigrate () {
      const _confirm = await this.$confirm(
        this.$t(
          'Các thành viên không online sẽ không được thêm vào kênh khi chuyển đổi. Bạn chắc chắn muốn tiếp tục?'
        ),
        this.$t('Cảnh báo')
      ).catch(_ => { })

      if (!_confirm) return

      this.isCreatingChannel = null
      this.isCreatedChannel = null
      this.isEncryptingChannel = null
      this.isEncryptedChannel = null
      this.isMigrateFailed = null
      this.userIdsNotRegister = []

      await this.handleMigrateConversation(true)
    },
    handleUseChatV1 () {
      this.$emit('onUseChatV1', true)
      this.closeMigrate()
    },
    goToChat (e, circle) {
      const twilio_conv_id = circle?.twilio_conversation?.twilio_conv_id
      this.setSelectedCircle(circle)
      this.messageToGroup(twilio_conv_id, e)
    }
  }
}
</script>

<style lang="scss" scoped></style>
